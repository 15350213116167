import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './DownloadPDF.scss';

import { graphql } from 'gatsby';
import { ReactComponent as PDFIcon } from '../../assets/svgs/pdf-extension.svg';

import { track } from '../../util';

function DownloadPDF({ data }) {
  const { url, parentRef } = data;
  const trackDownload = useCallback(() => track('Download', { name: 'PDF_Download' }), []);

  return (
    <a className="DownloadPDF" aria-label="Download PDF" download href={url} onClick={trackDownload} ref={parentRef}>
      <PDFIcon className="pdf-icon" />
    </a>
  );
}

DownloadPDF.propTypes = checkExtraProps({
  data: PropTypes.shape({
    url: PropTypes.string.isRequired,
    parentRef: PropTypes.shape({ current: PropTypes.object }),
  }),
});

DownloadPDF.defaultProps = {};

export default memo(DownloadPDF);

export const fragments = graphql`
  fragment DownloadPdf on WpPage_Flexiblelayout_FlexibleChildren_DownloadPdf {
    url
  }

  fragment DownloadPdf_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_DownloadPdf {
    url
  }
`;
